<template>
  <div class="pad-home">
    <HeaderBar
      title="个人信息"
      :showLeft="true"
    ></HeaderBar>
    <div class="userInfo">
      <div class="userInfo-item uname">
        <div class="userInfo-item-title">姓名</div>
        <div class="userInfo-item-content">
          <input
            type="text"
            placeholder="请输入你的姓名"
            v-model="username"
          >
        </div>
      </div>
      <div class="userInfo-item utel">
        <div class="userInfo-item-title">手机号</div>
        <div class="userInfo-item-content">
          <input
            type="text"
            placeholder="请输入你的姓名"
            v-model="utel"
            readonly
          >
        </div>
      </div>
      <div class="userInfo-item utel">
        <div class="userInfo-item-title">坐标位置</div>
        <div class="userInfo-item-content">
          <input
            type="text"
            placeholder="请输入你的坐标位置"
            v-model="getpos"
            readonly
          >
        </div>
      </div>
    </div>

    <!-- <div class="userInfo">
      <div class="userInfo-item uname">
        <div class="userInfo-item-title">紧急联系人姓名</div>
        <div class="userInfo-item-content">
          <input type="text"
                 placeholder="请输入紧急联系人姓名"
                 v-model="emergencyUname"
                 @blur.prevent="inputLoseFocus">
        </div>
      </div>
      <div class="userInfo-item">
        <div class="userInfo-item-title">紧急联系人电话</div>
        <div class="userInfo-item-content">
          <input type="text"
                 placeholder="请输入紧急联系人电话"
                 v-model="emergencyUtel"
                 @blur.prevent="inputLoseFocus">
        </div>
      </div>
    </div> -->

    <div
      class="saveBtn"
      @click.stop.prevent="save"
    >
      保存
    </div>
  </div>
</template>

<script>
import HeaderBar from "@/components/headerbar/headerbar";
import { getStore } from "../../lib/storage";
import { inputBlur } from "@/mixins/inputBlur";
import { updateUserInfo } from "@/service/";

export default {
  name: "userinfo",
  components: {
    HeaderBar,
  },
  mixins: [inputBlur],
  data() {
    return {
      username: JSON.parse(getStore("userInfo")).WeiXin || "",
      // utel: '+86-136****1234',
      emergencyUname: "",
      emergencyUtel: "",
    };
  },
  computed: {
    // uname: {
    //   get () {
    //     return JSON.parse(getStore('userInfo')).WeiXin
    //   },
    //   set () {

    //   }

    // },
    utel() {
      return JSON.parse(getStore("userInfo")).Tel;
    },
    getpos() {
      return getStore("pos");
    },
  },
  methods: {
    async save() {
      // console.log(this.username)
      let res = await updateUserInfo(
        this.username,
        JSON.parse(getStore("userInfo")).ID
      );
      if (res.code == 1) {
        console.log(JSON.parse(getStore("pos")));
        localStorage.setItem("userInfo", JSON.stringify(res.data));
        sessionStorage.setItem("userInfo", JSON.stringify(res.data));
        this.$router.push("/home");
      }
    },
  },
  mounted() {
    console.log(JSON.parse(getStore("pos")));
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/mixin.less";

.pad-home {
  padding-top: 94px;
}
.userInfo {
  width: 690px;
  height: 306px;
  margin: 30px auto;
  padding: 0 30px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  font-size: 30px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  box-sizing: border-box;

  &-item {
    height: 100px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;

    input {
      text-align: right;
      font-family: "PingFangSC-Regular";
      font-weight: 400;
    }
  }

  .uname,
  .utel {
    border-bottom: 2px solid #eee;
  }
}

.saveBtn {
  width: 690px;
  height: 76px;
  margin: 60px auto 0;
  background: rgba(12, 170, 50, 1);
  border-radius: 8px;
  font-size: 30px;
  line-height: 76px;
  font-family: "PingFangSC-Medium";
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
  .text-center;
}
</style>
